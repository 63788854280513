import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../store/store';

import { loginSuccess, loginFailure, logout } from '../store/features/auth/authSlice';

import axios from 'axios';

import { getCookie } from '../lib/cookies';


function WithoutAuthRoute() {

	const location = useLocation();
	const dispatch = useDispatch();

	let isAuthorized = useSelector((state: RootState) => state.auth.isAuthorized);

	if(!isAuthorized) {

		const checkAuthResult = checkAuth();

		if(!checkAuthResult) {

			return <Outlet />;
		}

		checkAuthResult
			.then((response: any) => {

				if(response.data.result.success) {
		
					dispatch(loginSuccess(response.data.result.user));

					isAuthorized = true;
		
				} else if(response.data.result.error) {
		
					dispatch(loginFailure(response.data.result.error));
				}
			
				return (
					isAuthorized === true 
						? <Navigate to="/" state={{ from: location }} replace />
						: <Outlet />
				);
			})
			.catch((err: any) => {

				return <Outlet />;
			});

	} else {

		return <Navigate to="/" state={{ from: location }} replace />;
	}

	return null;
	

	function checkAuth(): any {

		const token = getCookie('access_token') ? getCookie('access_token') : '';
	
		if(!token) {
	
			return undefined;
		}
	
		return axios.get('https://lksrv.labsdev.ru/user/check-auth/', {
			headers: {token: token}
		});
	}
}


export default WithoutAuthRoute;