import React from 'react';
import SitesListItem from './SitesListItem';

interface SitesListProps {
	data: {
		ID: number,
		NAME: string,
	}[] | never[]
} 

function SitesList(props: SitesListProps): JSX.Element {

	const outSitesList = props.data.map(
		(sitesListItemData: { ID:number, NAME: string }) => <SitesListItem key={ sitesListItemData.ID } data={ sitesListItemData } />
	);

	return (
		<>
			{ outSitesList }
		</>
	);
}

export default SitesList;
