function setCookie(name: string, value: string, expires: number = 0, path: string = '/'): void {

	let time = new Date()
	time.setTime(time.getTime() /*+ time.getTimezoneOffset()*60*1000*/ + expires);

	let date = new Date(Date.now() + expires);
	let dateVal = date.toUTCString();

	console.log(time);
	console.log(value);

	//alert(time);

	document.cookie = name + ' = ' + value + '; expires = ' + dateVal + '; path = ' + path;
}

function getCookie(name: string): string | undefined {

	const nameLenPlus = (name.length + 1);

	return document.cookie
		.split(';')
		.map(c => c.trim())
		.filter(cookie => {

			return cookie.substring(0, nameLenPlus) === `${name}=`;
		})
		.map(cookie => {

			return decodeURIComponent(cookie.substring(nameLenPlus));

		})[0] || undefined;
}


export { setCookie, getCookie };