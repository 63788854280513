import React, { useContext } from 'react';
import { SitesContext } from '../../../App';

interface SitesListItemProps {
	data: {
		ID: number;
		NAME: string;
	};
}

function SitesListItem(props: SitesListItemProps) {

	const context = useContext(SitesContext);

	if(!context) {
		throw new Error('SitesListItem must be used within a SitesProvider');
	}

	const { siteModalId, setSiteModalId } = context;

	const onSitesListItemClick = () => {
		console.log('onSitesListItemClick');
		setSiteModalId(props.data.ID);
	};

	return (
		<div onClick={onSitesListItemClick}>
			{props.data.ID}: {props.data.NAME}
		</div>
	);
};

export default SitesListItem;