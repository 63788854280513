import React from 'react';
import { useContext } from 'react';
import { SitesContext } from '../App';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

function Two() {

	const context = useContext(SitesContext);

	if(!context) {
		throw new Error('SitesListItem must be used within a SitesProvider');
	}

	const { siteModalId, setSiteModalId } = context;

	return (
		<>
			<Navigation/>
			<div className="Two">
				Is Two
			</div>
			{ siteModalId }
			<Footer/>
		</>
	);
}

export default Two;
