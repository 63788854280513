import React from 'react';
import { useState, useContext } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/store';
import { loginSuccess, loginFailure, logout } from '../store/features/auth/authSlice';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

import axios from 'axios';

function Home() {

	const dispatch = useDispatch();

	const isAuthorized = useSelector((state: RootState) => state.auth.isAuthorized);
	const userData = useSelector((state: RootState) => state.auth.user);
	const authError = useSelector((state: RootState) => state.auth.error);


	const [formValues, setFormValues] = useState({
		login: '',
		password: '',
	});


	const [formErrors, setFormErrors] = useState({
		login: false,
		password: false,
	});


	return (
		<div className="Home">
			<Navigation/>
			Is Home
			<div className="data">
				{ isAuthorized ? (
					<>
						<div className="is__authorized">{ userData && userData.id } { userData && userData.name }</div>
						<button onClick={ handleLogout }>Выйти</button>
					</>
				) : (
					<div className="auth-form">
						<div className="form__title">
							Вход на сайт
						</div>
						<div className="form__item">
							<div className="form__item-label">
								Логин
								{ formErrors.login &&  
									<div className="form__item-label-error">
										Заполните поле
									</div> 
								}
							</div>
							<input
								type="text"
								name="login"
								className={`form__item-input${formErrors.login ? ' form__item-input-error' : ''}`}
								value={ formValues.login }
								onChange={ handleChange }
								/> 
						</div>
						<div className="form__item">
							<div className="form__item-label">
								Пароль
								{ formErrors.password &&  
									<div className="form__item-label-error">
										Заполните поле
									</div>
								}
							</div>
							<input
								type="password"
								name="password"
								className={`form__item-input${formErrors.password ? ' form__item-input-error' : ''}`} 
								value={ formValues.password }
								onChange={ handleChange }
								/>
						</div>
						{ authError && 
							<div className="form__error">{ authError }</div>
						}
						<div className="form__item form__item-btns">
							<div className="form__item-btn">
								<button className="btn" onClick={ handleLogin }>Войти</button>
							</div>
							<div className="form__item-link">
								<a href="/two/">Напомнить пароль</a>
							</div>
						</div>
					</div>
				) }
			</div>
			<Footer/>
		</div>
	);


	function handleLogout() {

		dispatch(logout());
	};


	function handleChange(e: { target: { name: string; value: string; }; }) {

		dispatch(loginFailure(null));

		const { name, value } = e.target;

		setFormValues({
			...formValues,
			[name]: value
		});

		setFormErrors({
			...formErrors,
			[name]: value === ''
		});
	};


	function handleLogin() {

		dispatch(loginFailure(null));

		const newErrors = {
            login: formValues.login === '',
            password: formValues.password === ''
        };

        setFormErrors(newErrors);

        if(!newErrors.login && !newErrors.password) {
            
			submitLogin(formValues.login, formValues.password);
        }
	}


	async function submitLogin(login: string, password: string) {

		try {

			const response = await axios.post('https://lksrv.labsdev.ru/api/auth.php', {
					login: login,
					password: password,
				}
			);

			if(response.data.SUCCESS) {

				dispatch(loginSuccess(response.data.USER));

				setFormValues({
					login: '',
					password: '',
				});

			} else if(response.data.ERROR) {

				dispatch(loginFailure(response.data.ERROR));
			}

			console.log(response.data);

		} catch (error) {

			dispatch(loginFailure('Error'));
			console.error(error);
		}
	}


	
}

export default Home;
