import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { getCookie } from '../lib/cookies';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

import SitesList from '../components/pages/one/SitesList';

function One() {

	const [arSites, setArSites] = useState([]);

	useEffect(() => { getSitest() }, []);

	return (
		<div className="One">
			<Navigation/>
			Is One
			<SitesList data={ arSites } />
			<Footer/>
		</div>
	);


	async function getSitest() {

		const token = getCookie('access_token') ? getCookie('access_token') : '';

		/*
		try {
			const response = await axios.get('https://lksrv.labsdev.ru/site/get-user-site-list/', {
				headers: {token: token}
			});
			setArSites(response.data.result);
			console.log(response.data.result);
		} catch (error) {
			console.error(error);
		}
		*/
	}

}

export default One;
