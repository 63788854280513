import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/store';

import axios from 'axios';

function Footer() {

	const userData = useSelector((state: RootState) => state.auth.user);

	const [age, setAge] = useState(0);
	
	return (
		<div className="Footer">
			I am a Footer
			{ userData && ( <>
					<br />
					<div>Вы вошли как {userData.name}</div>
				</> 
			)}
			<br />
			{age}
			<br />
			<Link onClick={getUser} className='link' to="/">Home</Link>
		</div>
	);

	function handleClick(e: { preventDefault: () => void; }) {
		e.preventDefault();
		setAge(age + 1);
		alert('It is Click');
	}

	async function getUser() {
		try {
		  const response = await axios.get('/');
		  console.log(response);
		} catch (error) {
		  console.error(error);
		}
	}

	getUser();
}

export default Footer;
