import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setCookie } from '../../../lib/cookies';

interface userInterface {
	id: number,
	name: string,
	token: string,
}

interface AuthState {
	isAuthorized: boolean;
	user: userInterface | null;
	error: string | null;
}

const initialState: AuthState = {
	isAuthorized: false,
	user: null,
	error: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginSuccess(state, action: PayloadAction<userInterface>) {
			state.isAuthorized = true;
			state.user = action.payload;
			state.error = null;

			setCookie('access_token', action.payload.token, 10 * 1000);
		},
		loginFailure(state, action: PayloadAction<string | null>) {
			state.isAuthorized = false;
			state.user = null;
			state.error = action.payload;

			setCookie('access_token', '');
		},
		logout(state) {
			state.isAuthorized = false;
			state.user = null;
			state.error = null;

			setCookie('access_token', '');
		},
	},
});

export const { loginSuccess, loginFailure, logout } = authSlice.actions;

export default authSlice.reducer;