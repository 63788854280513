import React from 'react';
import { useState, createContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './store/store';

import './css/App.css';

import Login from './pages/Login';
import Page404 from './pages/Page404';
import Home from './pages/Home';
import One from './pages/One';
import Two from './pages/Two';

import PrivateRoute from './routes/PrivateRoute';
import WithoutAuthRoute from './routes/WithoutAuthRoute';

interface SitesContextProps {
	isAuthorized: boolean;
	setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
	siteModalId: number | undefined;
	setSiteModalId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const SitesContext = createContext<SitesContextProps | undefined>(undefined);

function App() {

	const [siteModalId, setSiteModalId] = useState<number | undefined>(undefined);
	const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
	
	return (
		<Provider store={ store }>
			<SitesContext.Provider value={{ isAuthorized, setIsAuthorized, siteModalId, setSiteModalId }}>
				<div className="App">
					<BrowserRouter>
						<Routes>
							<Route element={<WithoutAuthRoute />}>
								<Route path="/login/" element={<Login/>} />
							</Route>
							<Route element={<PrivateRoute />}>
								<Route path="/" element={<Home/>} />
								<Route path="/one/" element={<One/>} />
								<Route path="/two/" element={<Two/>} />
								<Route path="*" element={<Page404/>} />
							</Route>
						</Routes>
					</BrowserRouter>
				</div>
			</SitesContext.Provider>
		</Provider>
	);
}

export { SitesContext };
export default App;
