import React from 'react';

function Page404() {
	return (
		<div className="Page404">
			Is Page404
		</div>
	);
}

export default Page404;
