import React, { useEffect, useState } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../store/store';
import { loginSuccess, loginFailure, logout } from '../store/features/auth/authSlice';

import axios from 'axios';

import { getCookie } from '../lib/cookies';

function PrivateRoute() {
    
    const [loading, setLoading] = useState(true);
    const isAuthorized = useSelector((state: RootState) => state.auth.isAuthorized);
    const location = useLocation();
    const dispatch = useDispatch();

	const token = getCookie('access_token')
	//console.log(token);

    useEffect(() => {

        //const token = getCookie('access_token');
        //console.log(token + '!!!');

        if(!token) {
            dispatch(logout());
            setLoading(false);
            return;
        }

        checkAuth(token)
            .then((response: any) => {
                if(response.data.result.success) {
                    dispatch(loginSuccess(response.data.result.user));
                } else {
                    dispatch(loginFailure(response.data.result.error));
                }
            })
            .catch((err: any) => {
                dispatch(loginFailure(err.message));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [dispatch, token]);

    if(loading) {
        return <div>Loading...</div>;
    }

    if(!isAuthorized) {
        return <Navigate to="/login/" state={{ from: location }} replace />;
    }

    return <><Outlet />{ token }</>;

    function checkAuth(token: string): any {
        return axios.get('https://lksrv.labsdev.ru/user/check-auth/', {
            headers: { token: token }
        });
    }
}

export default PrivateRoute;