import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
	return (
		<div className="Navigation">
			<Link to="/">Home</Link>
			<Link to="/one/">One</Link>
			<Link to="/two/">Two</Link>
		</div>
	);
}

export default Navigation;
