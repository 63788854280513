import React from 'react';
import { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from '../store/store';
import { loginSuccess, loginFailure } from '../store/features/auth/authSlice';

import axios from 'axios';

function Login() {

	const dispatch = useDispatch();
	const location = useLocation();

	const isAuthorized = useSelector((state: RootState) => state.auth.isAuthorized);
	const authError = useSelector((state: RootState) => state.auth.error);


	const [formValues, setFormValues] = useState({
		login: '',
		password: '',
	});


	const [formErrors, setFormErrors] = useState({
		login: false,
		password: false,
	});


	return (isAuthorized 
		? <Navigate to="/" state={{ from: location }} replace />
		: <div className="auth__page">
			<div className="auth-form">
				<div className="form__title">
					Вход на сайт
				</div>
				<div className="form__item">
					<div className="form__item-label">
						Логин
						{ formErrors.login &&  
							<div className="form__item-label-error">
								Заполните поле
							</div> 
						}
					</div>
					<input
						type="text"
						name="login"
						className={`form__item-input${formErrors.login ? ' form__item-input-error' : ''}`}
						value={ formValues.login }
						onChange={ handleChange }
						/> 
				</div>
				<div className="form__item">
					<div className="form__item-label">
						Пароль
						{ formErrors.password &&  
							<div className="form__item-label-error">
								Заполните поле
							</div>
						}
					</div>
					<input
						type="password"
						name="password"
						className={`form__item-input${formErrors.password ? ' form__item-input-error' : ''}`} 
						value={ formValues.password }
						onChange={ handleChange }
						/>
				</div>
				{ authError && 
					<div className="form__error">{ authError }</div>
				}
				<div className="form__item form__item-btns">
					<div className="form__item-btn">
						<button className="btn" onClick={ handleLogin }>Войти</button>
					</div>
					<div className="form__item-link">
						<a href="/two/">Напомнить пароль</a>
					</div>
				</div>
			</div>
		</div>
	);
	


	function handleChange(e: { target: { name: string; value: string; }; }) {

		dispatch(loginFailure(null));

		const { name, value } = e.target;

		setFormValues({
			...formValues,
			[name]: value
		});

		setFormErrors({
			...formErrors,
			[name]: value === ''
		});
	};


	function handleLogin() {

		dispatch(loginFailure(null));

		const newErrors = {
            login: formValues.login === '',
            password: formValues.password === ''
        };

        setFormErrors(newErrors);

        if(!newErrors.login && !newErrors.password) {

			submitLogin(formValues.login, formValues.password);
        }
	}


	async function submitLogin(login: string, password: string) {

		try {

			const response = await axios.post('https://lksrv.labsdev.ru/user/auth/', {
					login: login,
					password: password,
				}
			);

			if(response.data.result.success) {

				dispatch(loginSuccess(response.data.result.user));

				setFormValues({
					login: '',
					password: '',
				});

			} else if(response.data.result.error) {

				dispatch(loginFailure(response.data.result.error));
			}

			console.log(response.data);

		} catch (error) {

			dispatch(loginFailure('Error'));
			console.error(error);
		}
	}
}

export default Login;
